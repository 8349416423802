import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  constructor(private platform: Platform, private storage: Storage) {}
  async initializeApp(): Promise<void> {
    await this.platform.ready();
   
    this.platform.backButton.subscribeWithPriority(1, () => { // to disable hardware back button on whole app
    });

  }
  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
  }
}
