import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenufloatComponent } from './menufloat/menufloat.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    MenufloatComponent
  ],
  exports:[
    MenufloatComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class ComponentesModule { }
