import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

//Impot Models
import {Login} from '../models/Login';

@Injectable({
  providedIn: 'root'
})
export class CheckAstService {
  //URL Usuario
 // url='https://api.turnosem.explor-k.cl/api/MobileUsers/';
  url='https://apibrigadistas.explor-k.com/api/MobileUsers/';

  constructor(public http: HttpClient) { }
  reqOpts = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      //'Accept': 'application/json',
      //'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      //'Access-Control-Allow-Headers': 'X-Requested-With'
    },
  };
  //autentica directo en la pagina APi
  authUser(Login: Login): Observable<any>{
    
    const body = new HttpParams()
    .set('user', Login.user)
    .set('pass', Login.pass);
    
      return this.http.post(this.url + 'Auth', body.toString(), this.reqOpts 
      );
  }
  
  
}
