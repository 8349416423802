import { NgModule, ViewChildren } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    
    path: 'home',
    children: [
      {
        path: "",
        loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'brigadistas',
        loadChildren: () => import('./home/brigadistas/brigadistas.module').then( m => m.BrigadistasPageModule)
      },
      {
        path: 'puntos-de-emergencia',
        loadChildren: () => import('./home/puntos-de-emergencia/puntos-de-emergencia.module').then( m => m.PuntosDeEmergenciaPageModule)
      },
      {
        path: 'alerta',
        children: [{
          path: "",
          loadChildren: () => import('./home/alerta/alerta.module').then( m => m.AlertaPageModule)
          },
          {
            path: 'mapa-alerta',
            loadChildren: () => import('./home/alerta/mapa-alerta/mapa-alerta.module').then( m => m.MapaAlertaPageModule)
          }
        ]
      },
    ],
    
  },
  {
    path: 'home-externos',
    children: [{
      path: "",
      loadChildren: () => import('./home-externos/home-externos.module').then( m => m.HomeExternosPageModule)
    },
    {
      path: 'brigadistas',
      loadChildren: () => import('./home-externos/brigadistas/brigadistas.module').then( m => m.BrigadistasPageModule)
    },
    {
      path: 'puntos-de-emergencia',
      loadChildren: () => import('./home-externos/puntos-de-emergencia/puntos-de-emergencia.module').then( m => m.PuntosDeEmergenciaPageModule)
    },
    {
      path: 'alerta',
      children: [{
        path: "",
        loadChildren: () => import('./home-externos/alerta/alerta.module').then( m => m.AlertaPageModule)
        },
        {
          path: 'mapa-alerta',
          loadChildren: () => import('./home-externos/alerta/mapa-alerta/mapa-alerta.module').then( m => m.MapaAlertaPageModule)
        }
      ]
    }]
  },
  {
    path:'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'confirmar-turno',
    loadChildren: () => import('./confirmar-turno/confirmar-turno.module').then( m => m.ConfirmarTurnoPageModule)
  },
  {
    path: 'confirmar-turno-ext',
    loadChildren: () => import('./confirmar-turno-ext/confirmar-turno-ext.module').then( m => m.ConfirmarTurnoExtPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
