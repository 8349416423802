import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MenuElementsInterface } from './interfaces/menudata';
import { Observable } from 'rxjs';
import { weatherdata } from './models/weatherdata.model';

@Injectable({
  providedIn: 'root'
})
export class WeatherInfoService {

  
  url ='https://api.meteo.explor-k.cl/WeatherForecast';
  

  constructor(public http: HttpClient) {}
  


  nombre = ["Infiernillo", 'Pérez Caldera', 'Paso Marchant', 'La Ermita', 'La Ruta y Tórtolas', 'Soldado/Chagres']
  tempMin : any

  getData(): Observable<weatherdata>{
    return this.http.get<weatherdata>(this.url)
  }

}
