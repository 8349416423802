import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';

import { MenuElementsInterface } from 'src/app/interfaces/menudata';
import { WeatherInfoService } from 'src/app/weather-info.service';

import { weatherdata } from 'src/app/models/weatherdata.model';
import { DomSanitizer } from '@angular/platform-browser';




@Component({
  selector: 'app-menufloat',
  templateUrl: './menufloat.component.html',
  styleUrls: ['./menufloat.component.scss'],
})
export class MenufloatComponent implements OnInit {

  

  constructor( public weather: WeatherInfoService, public sanitizer: DomSanitizer, public menu: MenuController ) {}
  
    
  
 
  
  
  isSwipeEnabled: any;
  datos: weatherdata
  fecha: Date

  
  getTMin(){
    this.weather.getData()
    .subscribe(data =>{
      this.datos= data;
    })
  }


  
  datosMenu: MenuElementsInterface [] 

  ngOnInit() {
    this.isSwipeEnabled=true;
    this.weather.getData()
    .subscribe(data =>{
      //this.datos= data;
      this.fecha= data.date
      this.datosMenu=[
        {
          nombre: this.weather.nombre[0],
          tempMin: data.inMin,
          tempMax: data.inMax,
          image: 'data:image/png;base64,'+data.imImage
        },
        {
          nombre: this.weather.nombre[1],
          tempMin: data.pcMin,
          tempMax: data.pcMax,
          image: 'data:image/png;base64,'+data.pcImage
        },
        {
          nombre: this.weather.nombre[2],
          tempMin: data.pmMin,
          tempMax: data.pmMax,
          image:'data:image/png;base64,'+data.pmImage
        },
        {
          nombre: this.weather.nombre[3],
          tempMin: data.leMin,
          tempMax: data.leMax,
          image: 'data:image/png;base64,'+data.leImage
        },
        {
          nombre: this.weather.nombre[4],
          tempMin: data.ltMin,
          tempMax: data.ltMax,
          image: 'data:image/png;base64,'+data.ltImage
        },
        {
          nombre: this.weather.nombre[5],
          tempMin: data.scMin,
          tempMax: data.scMax,
          image: 'data:image/png;base64,'+data.scImage
        }
      ];
    })
    
  }

  
}

